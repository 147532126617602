body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
}

/* about.css */
.section {
  padding: 10px;
}

.itemContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.item {
  padding: 0 50px 50px 50px;
  width: 100%;
  display: flex;
  align-items: center;
}

.item-icon {
  font-size: 64px;
  text-align: center;
}

@media all and (max-width: 580px) {
  /* We invert order of first sidebar and main
   * And tell the main element to take twice as much width as the other two sidebars
   */
  .itemContainer {
    flex-direction: column;
    justify-content: center;
  }
}

/* gallery.css */

:not(.fullscreen) .image-gallery-image img {
  min-height: 80px;
  max-height: 75vh;
  object-fit: contain;
}

.fullscreen .image-gallery-image img{
  object-fit: contain;
  min-height: 90vh;
}

.image-gallery-fullscreen-button::before, .image-gallery-play-button::before, .image-gallery-left-nav::before, .image-gallery-right-nav::before{
  color: #333;
}

.image-gallery-fullscreen-button:hover::before, .image-gallery-play-button:hover::before, .image-gallery-left-nav:hover::before, .image-gallery-right-nav:hover::before {
  color: #f7b320;
}

.image-gallery-thumbnail.active {
  border: 4px solid #f7b320;
}

.image-gallery-fullscreen-button, .image-gallery-play-button, .image-gallery-left-nav, .image-gallery-right-nav {
  z-index: 1;
}

/* map.css */
.contact {
  padding-top: 64px;
}

.contact-data {
  margin-bottom: 5px;
}

.contact-data span {
  margin-right: 8px;
  display: inline-block;

}

.map-container {
  margin-bottom: 80px;
  height: fit-content;
}

.mt-50 {
  margin-top: 50px;
}

.ntak-number {
  padding-top: 270px;
  font-size: 14px;
}