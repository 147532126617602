.cover-container .cover-text{
    font-family: 'Amatic SC', cursive;
    font-size: 3em;
    color: #fefefe;
    font-weight: 300;
}

.language-picker {
    position: absolute;
    top: 5px;
    right: 20px;
    color: white;
}

.language-picker a {
    color: white;
}