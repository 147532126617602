:not(.fullscreen) .image-gallery-image img {
    min-height: 80px;
    max-height: 75vh;
    object-fit: contain;
}

.fullscreen .image-gallery-image img{
    object-fit: contain;
    min-height: 90vh;
}

.image-gallery-fullscreen-button::before, .image-gallery-play-button::before, .image-gallery-left-nav::before, .image-gallery-right-nav::before{
    color: #333;
}

.image-gallery-fullscreen-button:hover::before, .image-gallery-play-button:hover::before, .image-gallery-left-nav:hover::before, .image-gallery-right-nav:hover::before {
    color: #f7b320;
}

.image-gallery-thumbnail.active {
    border: 4px solid #f7b320;
}

.image-gallery-fullscreen-button, .image-gallery-play-button, .image-gallery-left-nav, .image-gallery-right-nav {
    z-index: 1;
}
