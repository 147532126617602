.header-brand{
    font-family: 'Amatic SC', cursive;
    font-size: 1.8em;
}

.navbar {
    border-top: none;
}

.navbar.navbar-default{
    box-shadow: none;
}

.navbar-default .container {
    border-bottom: 1px solid #e1e1e1;
    background-color: #fff;
}

.navbar-collapse {
    overflow: hidden;
    transition: height .3s ease; 
}

